<template>
<div>
  <div class="row">
    <div class="col-md-12">
        <div class="card card-outline card-primary">
              <div class="card-header">
                <h2 class="card-title">Umsätze Händler</h2>
                  <br/>
                  <br/>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Von:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control form-control-sm" v-model="params.start_date" />
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Bis:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control form-control-sm" v-model="params.stop_date" />
                        </div>  
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Hersteller</label>
                        <div class="col-md-9">
                          <select class="form-control form-control-sm" v-model="params.producer_id">
                            <option :value="null">-- Filter Hersteller --</option>
                            <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.number }} - {{ producer.name }}</option>
                          </select>
                        </div>   
                      </div>
                    </div>
                    
                    <div class="col-md-2">
                      <button class="btn btn-primary btn-sm" @click="loadSales"><i class="fas fa-sync"></i></button>
                    </div>
                  </div>
              </div>

              <div class="card-body">
                    <Chart v-if="loaded == true" :chartData="chartdata" style="height: 350px; width: 100%;"/>        
              </div>
        </div>
        
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary card-outline">
          <div class="card-header">
            <h2 class="card-title">Händler</h2>
          </div>

          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <th>Händler</th>
                <th>Umsatz(Brutto)</th>
                <th>Aktion</th>
                <!-- <th>Einkauf(Brutto)</th>
                <th>Marge (Brutto)</th>
                <th>Umsatz(Netto)</th>
                <th>Einkauf(Netto)</th>
                <th>Marge(Netto)</th>
                <th>Absatzmenge</th>  -->
              </thead>
              <tbody>
                <tr v-for="dealer in dealers" :key="dealer.id">
                  <td>{{ dealer.name }}</td>
                  <td>{{ dealer.sales | toCurrency }}</td>
                  <td>
                    <router-link class="btn btn-success btn-sm" :to="{'name': 'dealers.details', 'params': {'id': dealer.id, 'stats': true}}"><i class="fas fa-chart-pie"></i></router-link>
                  </td>
                </tr>

                <tr class="table-primary text-bold">
                  <td>Gesamt</td>
                  <td>{{ Number(sum) | toCurrency }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
// import moment from 'moment'

import Chart from './components/DealerChart';
export default {
    name: "StatisticsSales",
    title: "Umsätze",

    components: {
        Chart,
    },

    data(){
        return{
            producers: [],
            params: {
                producer_id: null,
                start_date: null,
                stop_date: null,
            },    
            sum: 0,
            loaded: false,
            dealers: [],      
            chartdata: {
                'labels': '',
                'datasets': '',
            },
        }
    },

    methods:{
      

    loadSales(){
        this.loaded = false;
            this.axios
            .get("/statistics/dealers", {
              params:{
                ...this.params
              }              
            })
            .then((response) => {
                this.dealers = response.data.data.dealers;
                this.chartdata.labels = response.data.data.labels;
                this.chartdata.datasets = response.data.data.datasets;
                this.sum = response.data.data.summe;
                this.loaded = true;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.loaded = false;
            });
        },

        loadProducers()
        {
          this.axios
            .get("/producers", {
              params:{
                per_page: 1000,
              }
            })
            .then((response) => {
                this.producers = response.data.data;
                //this.groups = response.data.data[0].groups;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },
    },

    mounted(){
        this.loadSales();
        this.loadProducers();
    }
}
</script>

<style>

</style>